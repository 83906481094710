import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styles from "./scss/hero.module.scss"

const hero = ({ slogan, cta = true }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHeroImage {
          image {
            description
            fluid {
              src
              srcWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section className={styles.hero}>
        <section className={styles.innerWrapper}>
          <picture>
            <source
              srcSet={data.contentfulHeroImage.image.fluid.srcWebp}
              type="image/webp"
            />
            <source
              srcSet={data.contentfulHeroImage.image.fluid.src}
              type="image/png"
            />
            <img
              src={data.contentfulHeroImage.image.fluid.src}
              alt={data.contentfulHeroImage.image.description}
            />
          </picture>
          <div className={styles.text}>
            <h1>Content Visit</h1>
            <h4>{slogan}</h4>
            {cta && (
              <div className={styles.more}>
                <a href="/contact">Get in touch</a>
                <Link to="#services">Our Services</Link>
              </div>
            )}
          </div>
        </section>
      </section>
    )}
  />
)
export default hero
